import '../scss/main.scss';
import $ from 'jquery';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';
import ResizeObserver from 'resize-observer-polyfill';
import Drift from 'drift-zoom';
import 'drift-zoom/dist/drift-basic.min.css';
// import { createApp } from 'vue';
// import { createPinia } from 'pinia';
// import { useRouter } from './route';
// import App from '../vue/App.vue';

// Vue.js
// createApp(App).use(createPinia()).use(useRouter).mount('#app');

window.ResizeObserver = ResizeObserver;

// jQuery
$(function () {
  // drawer navi
  $('.js-drawer-navi-button').on('click', function () {
    $('.js-drawer-navi').fadeIn();
  });
  $('.js-drawer-navi-close-button').on('click', function () {
    $('.js-drawer-navi').fadeOut();
  });

  // scroll top
  $('.js-scroll-top').on('click', function () {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  });

  // scroll bar customize
  $('.js-scroll-bar-content').each(function() {
    new SimpleBar(this, {
      autoHide: false,
      clickOnTrack: false
    })
  });
});

const driftImgs = document.querySelectorAll('.js-zoom-image-element');
if(0 < driftImgs.length) {
  Array.from(driftImgs).map(img => {
    new Drift(img as HTMLElement, {
      inlinePane: 768,
      inlineOffsetY: -85,
      zoomFactor: 3,
      containInline: false
    });
  });
}

